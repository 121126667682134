<template>
    <div>
        <van-form v-model="submitForm" ref="submitForm" @submit="submitSave">
            <van-field
                v-model="submitForm.name"
                label="开户名"
                disabled
                />
            <!--<div class="company-name"><span>开户名</span>>{{submitForm.name}}</div>-->
            <van-field
                v-model="submitForm.accountName"
                label="开户行"
                placeholder="开户行"/>
            <van-field
                v-model="submitForm.account"
                label="开户账号"
                placeholder="开户账号"/>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submitSave">修改</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { accountGet,accountUpdate } from '@/api/user'
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            submitForm:{
                accountName:'',
                account:'',
            }
        };
    },
    created(){
        this.getAccountDetail();
    },
    methods: {
        getAccountDetail(){
            accountGet().then((res)=>{
                this.submitForm.accountName = res.data.accountName;
                this.submitForm.account = res.data.account;
                this.submitForm.name = res.data.name;
            })
        },
        submitSave(){
            if(!this.submitForm.accountName){
                this.$toast.fail('开户行不能为空');
                return false;
            }
            if(!this.submitForm.account){
                this.$toast.fail('开户账号不能为空');
                return false;
            }
            accountUpdate(this.submitForm).then(response => {
                this.$toast.success(response.msg);
            });
        }
    },
};
</script>
